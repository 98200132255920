import React from 'react'




const Bulletin = props => {
    return (
        <div className='Bulletin-Container NoBars'>
            <h2>Bulletin and Announcements</h2>

            <ul className='NoBars'>   
                <li>
                <a href={`/assets/Bulletin/2024/9-15-2024.pdf`} target='blank'>View</a>

                    <h3>Bulletin 9-15-2024</h3>
                    {/* <img src="/assets/Bulletin/bulletinImg.png" alt="" /> */}

                </li>
                <li>
                <a href="/assets/images/ChurchPicnic.png" target='blank'>View</a>

                    <h3>Church Picnic 9-29-2024</h3>
                    {/* <img src="/assets/images/ChurchPicnic.png" alt="" /> */}

                </li>
                <li>
                <a href="/assets/images/YouthMumFlyer.png" target='blank'>View</a>

                    <h3>Youth Mum Fundraiser</h3>
                    {/* <img src="/assets/images/YouthMumFlyer.png" alt="" /> */}

                </li>

                <li>
                <a href="/assets/images/YouthMumFlyer.png" target='blank'>View</a>
                    <h3>Cursillo</h3>
                    {/* <img src="/assets/images/Curillo.png" alt="" /> */}

                </li>
            

            </ul>

        </div>
    )
}

export default Bulletin