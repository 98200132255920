import React from 'react';

function Homily() {

    
    return (
        <div className='SundayHomily'>
            <main>
                <a className='MainBTN' href={`/assets/Homily/9-15-2024.pdf`} download>📄Read Sunday Homily  </a>
            </main>

            <footer>
                <h3>By Fr. Julian 9-15-2024</h3>
            </footer>
        </div>
    );
}

export default Homily;